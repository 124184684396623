import { notification } from 'antd';

interface NotificationInput {
  message: string;
  description?: string;
}

export const useNotifications = (): {
  showSuccess: (input: NotificationInput) => void;
  showError: (input: NotificationInput) => void;
  showWarning: (input: NotificationInput) => void;
} => {
  const showNotification = (
    type: 'success' | 'error' | 'warning',
    input: NotificationInput,
  ): void => {
    notification[type]({
      message: input.message,
      description: input.description,
    });
  };

  const showSuccess = (input: NotificationInput): void =>
    showNotification('success', input);
  const showError = (input: NotificationInput): void =>
    showNotification('error', input);
  const showWarning = (input: NotificationInput): void =>
    showNotification('warning', input);

  return { showError, showSuccess, showWarning };
};
