import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  hi: { id: 'hi', defaultMessage: 'hi' },
  error: { id: 'error', defaultMessage: 'error' },
  or: { id: 'or', defaultMessage: 'or' },
  success: { id: 'success', defaultMessage: 'success' },
  warning: { id: 'warning', defaultMessage: 'warning' },
  search: { id: 'search', defaultMessage: 'search' },
  new: { id: 'new', defaultMessage: 'new' },
  name: { id: 'name', defaultMessage: 'name' },
  organization: { id: 'organization', defaultMessage: 'organization' },
  description: { id: 'description', defaultMessage: 'description' },
  points: { id: 'points', defaultMessage: 'points' },
  create: { id: 'create', defaultMessage: 'create' },
  edit: { id: 'edit', defaultMessage: 'edit' },
  submit: { id: 'submit', defaultMessage: 'submit' },
  delete: { id: 'delete', defaultMessage: 'delete' },
  assign: { id: 'assign', defaultMessage: 'assign' },
  close: { id: 'close', defaultMessage: 'close' },
  done: { id: 'done', defaultMessage: 'done' },
  more: { id: 'more', defaultMessage: 'more' },
  showMore: { id: 'showMore', defaultMessage: 'show more' },
  removeFile: { id: 'removeFile', defaultMessage: 'remove file' },
  active: { id: 'active', defaultMessage: 'active' },
  pending: { id: 'pending', defaultMessage: 'pending' },
  deleteSelected: { id: 'deleteSelected', defaultMessage: 'delete selected' },
  confirmationMessage: {
    id: 'confirmationMessage',
    defaultMessage: '{count} items selected. Are you sure you want to delete?',
  },
  copy: { id: 'copy', defaultMessage: 'copy' },
  linkCopied: { id: 'linkCopies', defaultMessage: 'link copied' },
  notAllowed: {
    id: 'notAllowed',
    defaultMessage: 'you are not allowed to perform this action',
  },
  emptyState: {
    id: 'emptyState',
    defaultMessage: 'Nothing in here',
  },
  emptyFeed: {
    id: 'noFeed',
    defaultMessage: 'No feed yet',
  },
  total: {
    id: 'total',
    defaultMessage: 'total',
  },
  today: {
    id: 'today',
    defaultMessage: 'today',
  },
  dangerZone: {
    id: 'dangerZone',
    defaultMessage: 'danger zone',
  },
  privacyNotice: {
    id: 'privacyNotice',
    defaultMessage: 'Privacy notice',
  },
});
