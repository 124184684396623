import get from 'lodash/get';
import {
  Challenge,
  useGetChallengesQuery,
  Attempt,
  useGetMyAttemptsQuery,
} from '../graphql';
import { FetchPolicy } from '@apollo/client';

export const useChallenges = (
  variables: Record<string, any>,
  fetchPolicy: FetchPolicy = 'network-only',
): {
  challenges: Challenge[];
  myAttempts: Attempt[];
  loading: boolean;
  called: boolean;
} => {
  const { data, loading, called } = useGetChallengesQuery({
    variables,
    fetchPolicy,
  });

  const {
    data: attemptsData,
    loading: attemptsLoading,
    called: attemptsCalled,
  } = useGetMyAttemptsQuery({
    fetchPolicy,
  });

  return {
    challenges: get(data, 'challenges', []) as Challenge[],
    myAttempts: get(attemptsData, 'myAttempts', []) as Attempt[],
    loading: loading || attemptsLoading,
    called: called && attemptsCalled,
  };
};
