import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import {
  QUEEN_BLUE,
  ZOMP_BLUE,
  WHITE,
  BACKGROUND_COLOR,
} from 'src/style/colors';
import { useGetStatsQuery } from 'src/utils/graphql';
import upperFirst from 'lodash/upperFirst';
import { messages } from 'src/utils/translations/messages/dashboard';

const Wrapper = styled.div`
  background: linear-gradient(to right, ${QUEEN_BLUE}, ${ZOMP_BLUE});
  box-shadow: 0 8px 8px ${BACKGROUND_COLOR};
  color: ${WHITE};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  padding: 8px 16px;
  text-align: center;
  width: 100%;
`;

export const Status: React.FC = () => {
  const { formatMessage } = useIntl();
  const { data } = useGetStatsQuery();
  const position = data?.stats?.teamPosition || 0;
  const behind = data?.stats?.behind || 0;
  const points = data?.stats?.points || 0;

  return (
    <Wrapper>
      {position > 1 &&
        points > 0 &&
        upperFirst(
          formatMessage(messages.pointsBehind, {
            count: behind,
            position: position - 1,
          }),
        )}
      {points === 0 && upperFirst(formatMessage(messages.welcome))}
      {position === 1 &&
        points > 0 &&
        upperFirst(formatMessage(messages.leadingInfo))}
    </Wrapper>
  );
};
