import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import { CenterAlignment, Alignment } from '../Alignment';
import { MAIZE_CRAYOLA_YELLOW, TEXT_COLOR } from 'src/style/colors';
import { Logo } from './Navigation/Logo';
import { PrivacyNotice } from '../PrivacyNotice';

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;

  @media (max-width: 700px) {
    background: ${MAIZE_CRAYOLA_YELLOW};

    &::after {
      content: '';
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, transparent, white 140%);
    }
  }
`;

const LogoWrapper = styled.div`
  padding: 12px 24px;

  @media (max-width: 700px) {
    text-align: center;
  }
`;

const Center = styled(CenterAlignment)`
  position: absolute;

  @media (max-width: 700px) {
    z-index: 10;
    top: 64px;
    transform: translate(-50%, 0);
  }
`;

const FormWrapper = styled(Card)<{ noForm: boolean }>`
  max-width: ${(p) => (p.noForm ? 'min-content' : '360px')};
  background-color: transparent;
  text-align: center;
  border: none;
  padding: 32px 16px;
  margin: auto;

  @media (max-width: 700px) {
    width: 100%;
    max-width: 100vw;
    padding: 0;
    margin: 0;
  }
`;

const Circles = styled.div<{ shape: string }>`
  background: url(${(p) =>
      `${process.env.PUBLIC_URL}/images/${p.shape}_shape.png`})
    no-repeat center;
  background-size: contain;
  width: 100vw;
  margin: auto;
  max-height: 100vh;

  @media (max-width: 700px) {
    background: none;
    max-height: none;
  }
`;

const PeopleImg = styled.img<{ right?: boolean }>`
  display: block;
  position: absolute;
  ${(p) => {
    if (p.right) {
      return { right: '-10vw' };
    } else {
      return { left: '-5vw' };
    }
  }}
  bottom: 0;
  width: 50vw;

  @media (max-width: 700px) {
    display: ${(p) => (p.right ? 'block' : 'none')};
    right: -50vw;
    width: 150vw;
    position: fixed;
    z-index: 0;
  }
`;

const NoticeWrapper = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`;

const MobileNotice = styled(Alignment)`
  display: none;

  & > a {
    color: ${TEXT_COLOR};
  }

  @media (max-width: 700px) {
    display: flex;
  }
`;

interface AuthLayoutProps {
  shape?: 'login' | 'register';
  noForm?: boolean;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  shape = 'login',
  noForm = false,
}) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <NoticeWrapper>
          <PrivacyNotice />
        </NoticeWrapper>
        <Logo />
      </LogoWrapper>

      <Center>
        <Circles shape={shape}>
          <FormWrapper noForm={noForm}>{children}</FormWrapper>

          <MobileNotice justifyContent="center">
            <PrivacyNotice />
          </MobileNotice>
        </Circles>
      </Center>

      <PeopleImg src="/images/login_left.png" alt="people_waving" />

      <PeopleImg src="/images/login_right.png" alt="people_waving" right />
    </Wrapper>
  );
};
