import React from 'react';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import styled from 'styled-components';

import { useCurrentRoute, useCurrentUser } from 'src/utils/hooks';
import { ROUTE_TABS } from 'src/utils/helpers/routing';
import upperFirst from 'lodash/upperFirst';
import { messages } from 'src/utils/translations/messages/routes';
import { commonMessages } from 'src/utils/translations/common-messages';
import { SECONDARY_COLOR, WHITE } from 'src/style/colors';

const TabsWrapper = styled.div`
  z-index: 20;
  margin-left: 328px;
  padding: 4px 0;
  height: 40px;
  width: auto;

  @media (max-width: 700px) {
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
    width: 100%;
  }
`;

const TabLink = styled.span`
  font-size: 28px;
  font-weight: 400;
  margin-right: 24px;
  white-space: nowrap;
  line-height: 42px;

  &:last-child {
    margin-right: 0;
  }

  & .active {
    color: ${SECONDARY_COLOR};
  }

  @media (max-width: 1000px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 16px;
    margin-right: 8px;

    & a {
      color: ${WHITE};
    }
  }
`;

export const Tabs: React.FC = () => {
  const { formatMessage } = useIntl();
  const currentRoute = useCurrentRoute();
  const { user } = useCurrentUser();
  const tabs = pickBy(
    ROUTE_TABS[currentRoute],
    (key) =>
      !key.invisible &&
      (!key.only || key.only?.some((role) => user?.roles.includes(role))),
  );

  return (
    <TabsWrapper>
      {Object.keys(tabs).map((tab) => (
        <TabLink key={tab}>
          <NavLink
            to={get(ROUTE_TABS, [currentRoute, tab, 'path'])}
            exact={true}
          >
            {upperFirst(
              formatMessage(get({ ...messages, ...commonMessages }, tab)),
            )}
          </NavLink>
        </TabLink>
      ))}
    </TabsWrapper>
  );
};
