import React from 'react';
import { Result, Button, Card } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import upperFirst from 'lodash/upperFirst';
import styled from 'styled-components';

import { messages } from 'src/utils/translations/messages/errors';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ErrorCard = styled(Card)`
  width: 50vw;
  min-width: 400px;
`;

export const UnexpectedError: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <ErrorCard>
        <Result
          icon={<FrownOutlined />}
          title={upperFirst(formatMessage(messages.genericError))}
          extra={
            <a href="/">
              <Button type="primary">
                {upperFirst(formatMessage(messages.backToDashboard))}
              </Button>
            </a>
          }
        />
      </ErrorCard>
    </Wrapper>
  );
};
