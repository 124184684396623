import get from 'lodash/get';
import { Team, useGetTeamNamesQuery } from '../graphql';

export const useTeams = (): {
  teams: Team[];
  loading: boolean;
} => {
  const { data, loading } = useGetTeamNamesQuery();

  return {
    teams: get(data, 'teams', []) as Team[],
    loading,
  };
};
