import React, { createContext, useState, useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import get from 'lodash/get';

import locales from './locales';
import { useCurrentUser } from '../hooks';

type TranslationProviderProps = {
  defaultLocale: string;
};

type TranslationContextType = {
  setLocale: (locale: string) => void;
  locale: string;
  locales: string[];
};

const TranslationContext = createContext<TranslationContextType>({
  locale: '',
  setLocale: () => null,
  locales: [],
});

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  defaultLocale,
  children,
}) => {
  const { user } = useCurrentUser();
  const [locale, setLocale] = useState<string>(defaultLocale);

  const handleError = (err: any): void => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    console.warn('Missing translation', err.message);
  };

  useEffect(() => {
    if (user) {
      setLocale(user.language.toLowerCase());
    }
  }, [user]);

  return (
    <TranslationContext.Provider
      value={{ locale, setLocale, locales: Object.keys(locales) }}
    >
      <IntlProvider
        locale={locale}
        messages={get(locales, locale)}
        onError={handleError}
      >
        {children}
      </IntlProvider>
    </TranslationContext.Provider>
  );
};

export const useTranslation = (): TranslationContextType =>
  useContext(TranslationContext);
