import React from 'react';
import { useIntl } from 'react-intl';
import { commonMessages } from 'src/utils/translations/common-messages';

export const PrivacyNotice: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <a href="/docs/integrigo-privacy-notice.pdf" target="_blank">
      {formatMessage(commonMessages.privacyNotice)}
    </a>
  );
};
