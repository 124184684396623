import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  awaitingAttemps: {
    id: 'dashboard.awaitingAttempts',
    defaultMessage: 'there are {count} attempts awaiting approval',
  },
  awaitingAttemp: {
    id: 'dashboard.awaitingAttempt',
    defaultMessage: 'there is 1 attempt awaiting approval',
  },
  attemptApproved: {
    id: 'dashboard.attemptApproved',
    defaultMessage: 'attempt approved',
  },
  attemptRejected: {
    id: 'dashboard.attemptRejected',
    defaultMessage: 'attempt rejected',
  },
  welcome: {
    id: 'dashboard.welcome',
    defaultMessage: 'Welcome to Integrigo!',
  },
  pointsBehind: {
    id: 'dashboard.pointsBehind',
    defaultMessage: 'you are {count} points behind the #{position} place',
  },
  equalPointsBehind: {
    id: 'dashboard.equalPointsBehind',
    defaultMessage:
      'User in front of you has the same number of points. Do some challenges!',
  },
  leadingInfo: {
    id: 'dashboard.leadingInfo',
    defaultMessage: 'Keep up the good work! You are leading in your team!',
  },
  timeline: {
    id: 'dashboard.timeline',
    defaultMessage: 'timeline',
  },
  progress: {
    id: 'dashboard.progress',
    defaultMessage: 'progress',
  },
  feed: {
    id: 'dashboard.feed',
    defaultMessage: 'feed',
  },
  emptyFeed: {
    id: 'dashboard.emptyFeed',
    defaultMessage: 'Feed is empty',
  },
  emptyAttempts: {
    id: 'dashboard.emptyAttempts',
    defaultMessage: 'Challenge not found or no attempts submitted',
  },
});
