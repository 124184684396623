import styled from 'styled-components';
import { Card } from 'antd';

export const ContentCard = styled(Card)<{ transparent?: number }>`
  margin-top: 62px;
  background-color: ${(p) => (p.transparent ? 'transparent' : 'auto')};

  @media (max-width: 700px) {
    margin-top: 104px;
  }
`;
