import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';

import { WHITE, ZOMP_BLUE, SECONDARY_COLOR } from 'src/style/colors';
import { Logo } from '../Navigation/Logo';
import { RouteEnum, getRoutePath } from 'src/utils/helpers/routing';
import { messages } from 'src/utils/translations/messages/routes';
import { messages as authMessages } from 'src/utils/translations/messages/auth';
import { PrivacyNotice } from '../../PrivacyNotice';

const Navigation = styled.div<{ open: number }>`
  transition: all 0.3s ease-in;
  position: fixed;
  clip-path: ${(p) =>
    p.open ? 'circle(100%)' : 'circle(0 at calc(100% - 30px) 0)'};
  background-color: ${WHITE};
  height: 105%;
  width: 105%;
  z-index: 20;
`;

const NaviButton = styled.div`
  transition: all 0.5s ease-in;
  border-radius: 32px;
  height: 50px;
  position: fixed;
  background-color: ${ZOMP_BLUE};
  padding: 12px;
  top: 6px;
  right: 10px;
`;

const OpenMenuButton = styled(Button)`
  margin: auto 0;
  color: ${WHITE};
  width: 100% !important;
  height: 100% !important;

  &:focus,
  &:active {
    color: ${WHITE};
  }
`;

const Option = styled.div<{ active: boolean }>`
  text-align: center;
  padding-top: 16px;

  & a,
  & button {
    font-size: 28px;
    color: ${(p) => (p.active ? SECONDARY_COLOR : 'auto')};
  }
`;

const LogoWrapper = styled.div`
  padding: 6px 24px;
`;

const Routes = styled.div`
  width: 100vw;
  height: auto;
`;

const NoticeWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  text-align: center;
  margin-bottom: 16px;
`;

interface MobileNavigationProps {
  availableRoutes: RouteEnum[];
  currentRoute: RouteEnum;
  showMenu: boolean;
  setShowMenu: (value: React.SetStateAction<boolean>) => void;
  onLogout: () => void;
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
  availableRoutes,
  currentRoute,
  showMenu,
  setShowMenu,
  onLogout,
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    setShowMenu(false);
  }, [currentRoute, setShowMenu]);

  return (
    <Navigation open={+showMenu}>
      <NaviButton>
        <OpenMenuButton
          size="small"
          type="link"
          onClick={() => setShowMenu((current) => !current)}
          icon={<MenuOutlined style={{ fontSize: '24px' }} />}
        />
      </NaviButton>

      <LogoWrapper>
        <Logo />
      </LogoWrapper>

      <Routes>
        {availableRoutes.map((route) => (
          <Option active={currentRoute === route} key={route}>
            <Link to={getRoutePath(route)}>
              {upperFirst(formatMessage(get(messages, route)))}
            </Link>
          </Option>
        ))}
        <Option active={false} key="logout">
          <Button type="link" size="small" onClick={onLogout}>
            {upperFirst(formatMessage(authMessages.logout))}
          </Button>
        </Option>
      </Routes>

      <NoticeWrapper>
        <PrivacyNotice />
      </NoticeWrapper>
    </Navigation>
  );
};
