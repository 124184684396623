import React from 'react';
import { UnexpectedError } from '../ErrorScreens';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(): void {
    this.setState({ hasError: true });

    if (process.env.NODE_ENV !== 'development') {
      // @TODO: Report error
    }
  }

  render(): JSX.Element | null {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <UnexpectedError />;
    }

    return children as JSX.Element | null;
  }
}
