import React from 'react';
import styled from 'styled-components';
import { TEXT_COLOR } from 'src/style/colors';

const Text = styled.span`
  color: ${TEXT_COLOR};
  font-size: 32px;
  letter-spacing: -2px;
`;

export const Logo: React.FC = () => <Text>Integrigo</Text>;
