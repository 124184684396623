import styled from 'styled-components';

export interface AlignmentProps {
  wrap?: boolean;
  responsive?: boolean;
  column?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  justifyContent?: string;
}

export const Alignment = styled.div<AlignmentProps>`
  display: flex;
  flex-direction: ${(p) => (p.column ? 'column' : 'row')};
  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
  height: ${(p) => (p.fullHeight ? '100%' : 'auto')};
  justify-content: ${(p) => p.justifyContent || 'auto'};
  flex-wrap: ${(p) => (p.wrap ? 'wrap' : 'auto')};

  @media (max-width: 700px) {
    flex-direction: ${(p) => (p.responsive || p.column ? 'column' : 'row')};
  }
`;

export const CenterAlignment = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
