import React, { useState } from 'react';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { getAvailablesRoutes } from 'src/utils/helpers/routing';
import { useCurrentUser, useCurrentRoute } from 'src/utils/hooks';
import { QUEEN_BLUE, ZOMP_BLUE, WHITE } from 'src/style/colors';
import { Logo } from '../Navigation/Logo';
import { MobileNavigation } from './MobileNavigation';

const Wrapper = styled.div`
  background: linear-gradient(to right, ${QUEEN_BLUE}, ${ZOMP_BLUE});
  display: none;
  justify-content: space-between;
  position: fixed;
  padding: 0 24px;
  width: 100vw;
  height: 60px;
  z-index: 20;

  @media (max-width: 700px) {
    display: flex;
  }
`;

const LogoWrapper = styled.div`
  margin: auto 0;
  & > span {
    color: ${WHITE};
  }
`;

const MenuButton = styled(Button)`
  margin: auto 0;
  color: ${WHITE};
  &:focus,
  &:active {
    color: ${WHITE};
  }
`;

interface MobileMenuProps {
  onLogout: () => void;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({ onLogout }) => {
  const { user } = useCurrentUser();
  const currentRoute = useCurrentRoute();
  const [showMenu, setShowMenu] = useState(false);
  const availableRoutes = getAvailablesRoutes(user?.roles);

  return (
    <>
      <Wrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>

        <MenuButton
          size="small"
          type="link"
          onClick={() => setShowMenu((current) => !current)}
          icon={<MenuOutlined style={{ fontSize: '24px' }} />}
        />
      </Wrapper>

      <MobileNavigation
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        availableRoutes={availableRoutes}
        currentRoute={currentRoute}
        onLogout={onLogout}
      />
    </>
  );
};
