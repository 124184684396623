import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dashboard: { id: 'dashboard', defaultMessage: 'dashboard' },
  users: { id: 'users', defaultMessage: 'users' },
  teams: { id: 'teams', defaultMessage: 'teams' },
  challenges: { id: 'challenges', defaultMessage: 'challenges' },
  settings: { id: 'settings', defaultMessage: 'settings' },
  admin: { id: 'adminPanel', defaultMessage: 'admin panel' },

  // dashboard tabs
  // dashboard - defined above
  approvals: { id: 'dashboard.approvals', defaultMessage: 'approvals' },
  attempts: { id: 'dashboard.attempts', defaultMessage: 'attempts' },
  feed: {
    id: 'dashboard.feed',
    defaultMessage: 'feed',
  },

  // challenges tabs
  browse: { id: 'challenges.browse', defaultMessage: 'browse' },
  list: { id: 'challenges.list', defaultMessage: 'list' },
  my: { id: 'challenges.my', defaultMessage: 'my attempts' },
  new_challenge: { id: 'challenges.new', defaultMessage: 'new challenge' },

  // teams

  // users

  // settings
  details: { id: 'settings.details', defaultMessage: 'details' },
});
