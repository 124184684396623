import get from 'lodash/get';
import { FetchPolicy } from '@apollo/client';
import { Challenge, useGetChallengesWithAttemptsQuery } from '../graphql';

export const useChallengesWithAttempts = (
  variables: Record<string, any>,
  fetchPolicy: FetchPolicy = 'network-only',
): {
  challenges: Challenge[];
  loading: boolean;
  called: boolean;
} => {
  const { data, loading, called } = useGetChallengesWithAttemptsQuery({
    variables,
    fetchPolicy,
  });

  return {
    challenges: get(data, 'challenges', []) as Challenge[],
    loading,
    called,
  };
};
