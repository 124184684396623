import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  register: { id: 'register', defaultMessage: 'register' },
  login: { id: 'login', defaultMessage: 'login' },
  logout: { id: 'logout', defaultMessage: 'logout' },
  joinOrganization: {
    id: 'joinOrganization',
    defaultMessage: 'join organization',
  },
  join: { id: 'join', defaultMessage: 'join' },
  email: { id: 'users.email', defaultMessage: 'email' },
  emailOrUsername: {
    id: 'users.emailOrUsername',
    defaultMessage: 'email or username',
  },
  resetPassword: {
    id: 'users.resetPassword',
    defaultMessage: 'reset password',
  },
  password: { id: 'users.password', defaultMessage: 'password' },
  passwordConfirmation: {
    id: 'users.passwordConfirmation',
    defaultMessage: 'password confirmation',
  },
  newPassword: { id: 'users.newPassword', defaultMessage: 'new password' },
  username: { id: 'users.username', defaultMessage: 'username' },
  firstName: { id: 'users.firstName', defaultMessage: 'first name' },
  lastName: { id: 'users.lastName', defaultMessage: 'last name' },
  language: { id: 'users.langauge', defaultMessage: 'language' },
  createNewOrganization: {
    id: 'organizations.createNew',
    defaultMessage: 'create new organization',
  },
  createAdminUser: {
    id: 'users.createAdmin',
    defaultMessage: 'create organization admin',
  },
  organizationName: {
    id: 'organizations.name',
    defaultMessage: 'organization name',
  },
  userSettings: {
    id: 'users.settings',
    defaultMessage: 'user settings',
  },
  accountCancelation: {
    id: 'organizations.cancel',
    defaultMessage: 'delete organization',
  },
  accountCancelationWarning: {
    id: 'organizations.cancelWarning',
    defaultMessage:
      'This operation is irreversible. Are you sure you want to delete this organization?',
  },
  cancelationFailure: {
    id: 'organizations.cancelationFailure',
    defaultMessage: 'Unable to delete organization. Please contact support.',
  },

  limitReached: {
    id: 'registration.limitReached',
    defaultMessage: 'we currently reached our users limit.',
  },
  tryOut: {
    id: 'registration.tryOut',
    defaultMessage: 'if you want to try Integrigo.app for free contact us',
  },
  getSoonAsap: {
    id: 'registration.getSoonAsap',
    defaultMessage: "We'll get to you as soon as we can.",
  },
});
