import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import upperFirst from 'lodash/upperFirst';
import { useIntl } from 'react-intl';

import {
  useGetMeQuery,
  useLogoutMutation,
  LogoutMutation,
} from 'src/utils/graphql';
import {
  QUEEN_BLUE,
  ZOMP_BLUE,
  WHITE,
  BACKGROUND_COLOR,
  SECONDARY_COLOR,
  TEXT_COLOR,
} from 'src/style/colors';
import { createMutator } from 'src/utils/graphql/mutator';
import { messages } from 'src/utils/translations/messages/auth';
import { MobileMenu } from '../MobileMenu';
import { Tabs } from './Tabs';

const Wrapper = styled.div`
  background-color: ${BACKGROUND_COLOR};
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  position: fixed;
  top: 0;
  width: calc(100vw - 16px);
  height: 88px;
  z-index: 10;

  @media (max-width: 700px) {
    top: 60px;
    background-color: ${TEXT_COLOR};
    box-shadow: none;
    height: 48px;
    padding: 0;
    width: 100%;
  }
`;

const MenuParent = styled.div`
  display: block;
  position: relative;
  width: 240px;
  margin-right: 8px;
  min-width: 56px;

  @media (max-width: 1200px) {
    width: 56px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const MenuSegment = styled.div<{ open: number }>`
  background: linear-gradient(to right, ${QUEEN_BLUE}, ${ZOMP_BLUE});
  color: ${WHITE};
  border-radius: ${(p) => (p.open ? '28px 28px 12px 12px' : '28px')};
  padding: 16px 24px;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 16px;
  }
`;

const Organization = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  right: 24px;
`;

const OrganizationName = styled.span`
  display: inline;
  white-space: nowrap;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const Dropdown = styled(MenuSegment)`
  height: ${(p) => (p.open ? 'auto' : 0)};
  min-width: 240px;
  padding: ${(p) => (p.open ? '16px 24px' : 0)};
  top: 60px;
  right: 0;
  border-radius: 12px;
`;

const Option = styled.div`
  padding-bottom: 4px;

  & a,
  & button {
    color: ${WHITE};
    font-size: 16px;
    padding: 0;

    &:hover {
      color: ${SECONDARY_COLOR};
    }
  }
`;

export const Menu: React.FC = () => {
  const { data } = useGetMeQuery();
  const { formatMessage } = useIntl();
  const [showMenu, setShowMenu] = useState(false);
  const [logoutMutation] = useLogoutMutation();

  const org = data?.me?.organization.name;

  const logout = createMutator<LogoutMutation>({
    mutation: logoutMutation,
    update: (cache) => {
      cache.modify({
        fields: {
          me(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  return (
    <>
      <Wrapper>
        <Tabs />
        <MenuParent>
          <MenuSegment open={+showMenu}>
            <Organization>
              <OrganizationName>{org}</OrganizationName>
              <Button
                size="small"
                type="link"
                onClick={() => setShowMenu((current) => !current)}
                style={{ color: WHITE }}
                icon={<MenuOutlined style={{ fontSize: '24px' }} />}
              />
            </Organization>
          </MenuSegment>
          <Dropdown open={+showMenu}>
            {showMenu && (
              <Option>
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    logout();
                    setShowMenu(false);
                  }}
                >
                  {upperFirst(formatMessage(messages.logout))}
                </Button>
              </Option>
            )}
          </Dropdown>
        </MenuParent>
      </Wrapper>

      <MobileMenu onLogout={() => logout()} />
    </>
  );
};
