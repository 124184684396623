import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { TranslationProvider } from 'src/utils/translations/locale-context';
import { AppRouter } from './routes';
import { client } from './utils/graphql/apollo';

const App: React.FC = () => (
  <ApolloProvider client={client}>
    <TranslationProvider defaultLocale="pl">
      <AppRouter />
    </TranslationProvider>
  </ApolloProvider>
);

export default App;
