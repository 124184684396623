import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { RouteEnum } from 'src/utils/helpers/routing';
import { PrivateRoute } from './PrivateRoute';
import { Loading } from 'src/components/UI/Loading';

// const Register = lazy(() => import('../views/auth/components/Register'));
const BlockedRegistration = lazy(
  () => import('../views/auth/components/BlockedRegistration'),
);
const Login = lazy(() => import('../views/auth/components/Login'));
const JoinOrganization = lazy(
  () => import('../views/auth/components/JoinOrganization'),
);
const Dashboard = lazy(() => import('../views/dashboard'));
const Users = lazy(() => import('../views/users'));
const Teams = lazy(() => import('../views/teams'));
const Challenges = lazy(() => import('../views/challenges'));
const Settings = lazy(() => import('../views/settings'));
const Admin = lazy(() => import('../views/admin'));

export const AppRouter: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* <Route path="/register" component={Register} /> */}
          <Route path="/register" component={BlockedRegistration} />
          <Route path="/login" component={Login} />
          <Route path="/invitation" component={JoinOrganization} />

          <PrivateRoute
            entity={RouteEnum.USERS}
            path="/users"
            component={Users}
          />
          <PrivateRoute
            entity={RouteEnum.TEAMS}
            path="/teams"
            component={Teams}
          />
          <PrivateRoute
            entity={RouteEnum.CHALLENGES}
            path="/challenges"
            component={Challenges}
          />
          <PrivateRoute
            entity={RouteEnum.SETTINGS}
            path="/settings"
            component={Settings}
          />
          <PrivateRoute
            entity={RouteEnum.ADMIN}
            path="/admin"
            component={Admin}
          />
          <PrivateRoute
            entity={RouteEnum.DASHBOARD}
            path="/"
            component={Dashboard}
          />
        </Switch>
      </Suspense>
    </Router>
  );
};
