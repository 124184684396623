import { User, useGetMeQuery, Role } from '../graphql';

export const useCurrentUser = (): {
  user?: User | null;
  isAdmin: boolean;
  loading: boolean;
} => {
  const { data, loading } = useGetMeQuery();

  return {
    user: data?.me as User,
    isAdmin: !!data?.me?.roles.includes(Role.Admin),
    loading,
  };
};
