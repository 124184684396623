import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  myAttempts: Array<Attempt>;
  teamAttempts: Array<Attempt>;
  me?: Maybe<User>;
  stats?: Maybe<Stats>;
  users: Array<User>;
  challenges?: Maybe<Array<Challenge>>;
  typeChallenges?: Maybe<Array<Challenge>>;
  challenge?: Maybe<Challenge>;
  organizations: Array<Organization>;
  team?: Maybe<Team>;
  teamStats?: Maybe<TeamStats>;
  teamProgress: Array<Progress>;
  teams: Array<Team>;
  feed: Array<History>;
};


export type QueryMyAttemptsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  since?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  rejected?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
};


export type QueryTeamAttemptsArgs = {
  rejected?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
  challengeId?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  input?: Maybe<UsersInput>;
};


export type QueryChallengesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChallengeOrder>;
  typeIn?: Maybe<Array<ChallengeType>>;
  currentOnly?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryTypeChallengesArgs = {
  type?: Maybe<ChallengeType>;
};


export type QueryChallengeArgs = {
  id: Scalars['Int'];
};


export type QueryTeamArgs = {
  id: Scalars['Int'];
};


export type QueryTeamStatsArgs = {
  teamId?: Maybe<Scalars['Int']>;
};


export type QueryTeamsArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryFeedArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type Attempt = {
  __typename?: 'Attempt';
  id: Scalars['Int'];
  description: Scalars['String'];
  approved?: Maybe<Scalars['Boolean']>;
  rejected?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Int'];
  file?: Maybe<Scalars['String']>;
  user: User;
  team?: Maybe<Team>;
  challenge: Challenge;
  history?: Maybe<Array<History>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email: Scalars['String'];
  validationHash?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  language: Language;
  organization: Organization;
  team?: Maybe<Team>;
  attempts?: Maybe<Array<Attempt>>;
  history?: Maybe<Array<History>>;
};

/** User role */
export enum Role {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Superuser = 'SUPERUSER'
}

export enum Language {
  En = 'EN',
  Pl = 'PL'
}

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['Int'];
  name: Scalars['String'];
  history: Array<History>;
  teams: Array<Team>;
  users: Array<User>;
  challenges: Array<Challenge>;
};

export type History = {
  __typename?: 'History';
  id: Scalars['Int'];
  entity: HistoryEntity;
  operation: HistoryOperation;
  timestamp: Scalars['Int'];
  description?: Maybe<Scalars['Int']>;
  attempt: Attempt;
  user: User;
  team: Team;
  organization: Organization;
};

export enum HistoryEntity {
  Challenge = 'CHALLENGE',
  Attempt = 'ATTEMPT'
}

export enum HistoryOperation {
  Create = 'CREATE',
  Update = 'UPDATE',
  Approve = 'APPROVE',
  Reject = 'REJECT'
}

export type Team = {
  __typename?: 'Team';
  id: Scalars['Int'];
  name: Scalars['String'];
  organization: Organization;
  users?: Maybe<Array<User>>;
  attempts?: Maybe<Array<Attempt>>;
  history?: Maybe<Array<History>>;
};

export type Challenge = {
  __typename?: 'Challenge';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  points: Scalars['Int'];
  pointsDifference: Scalars['Int'];
  type: ChallengeType;
  repeats: Scalars['Int'];
  /** In days */
  repeatInterval?: Maybe<Scalars['Int']>;
  autoApprove: Scalars['Boolean'];
  startDate: Scalars['String'];
  deadline?: Maybe<Scalars['String']>;
  previousChallenge?: Maybe<Challenge>;
  organization: Organization;
  attempts?: Maybe<Array<Attempt>>;
};

/** Challenge types */
export enum ChallengeType {
  OneTime = 'ONE_TIME',
  Repeatable = 'REPEATABLE',
  FirstComeFirstServed = 'FIRST_COME_FIRST_SERVED',
  Chain = 'CHAIN',
  QuestionAndVote = 'QUESTION_AND_VOTE'
}

export type Stats = {
  __typename?: 'Stats';
  teamPosition?: Maybe<Scalars['Int']>;
  organizationPosition?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  behind?: Maybe<Scalars['Int']>;
  approvedAttempts?: Maybe<Scalars['Int']>;
  awaitingAttempts?: Maybe<Scalars['Int']>;
};

export type UsersInput = {
  validated?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

export enum ChallengeOrder {
  Current = 'CURRENT',
  Newest = 'NEWEST'
}

export type TeamStats = {
  __typename?: 'TeamStats';
  organizationPosition?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  approvedAttempts?: Maybe<Scalars['Int']>;
  awaitingAttempts?: Maybe<Scalars['Int']>;
  usersNumber?: Maybe<Scalars['Int']>;
};

export type Progress = {
  __typename?: 'Progress';
  user: User;
  results: Array<Result>;
};

export type Result = {
  __typename?: 'Result';
  date: Scalars['String'];
  points?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAttempt: Attempt;
  updateAttempt: Attempt;
  toggleAttemptApprove?: Maybe<Attempt>;
  toggleAttemptReject?: Maybe<Attempt>;
  register: User;
  login?: Maybe<User>;
  setSuperOrganization: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  sendInvitation: Scalars['Boolean'];
  joinOrganization: User;
  assignTeam: Array<User>;
  deleteUsers: Scalars['Boolean'];
  updateMe?: Maybe<User>;
  updateUser?: Maybe<User>;
  uploadFile?: Maybe<Scalars['String']>;
  createChallenge: Challenge;
  updateChallenge?: Maybe<Challenge>;
  deleteChallenge: Scalars['Boolean'];
  deleteOrganization: Scalars['Boolean'];
  createTeam: Team;
  deleteTeams: Scalars['Boolean'];
};


export type MutationCreateAttemptArgs = {
  input: CreateAttemptInput;
};


export type MutationUpdateAttemptArgs = {
  input: UpdateAttemptInput;
};


export type MutationToggleAttemptApproveArgs = {
  input: IdInput;
};


export type MutationToggleAttemptRejectArgs = {
  input: IdInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationSetSuperOrganizationArgs = {
  input: IdInput;
};


export type MutationSendInvitationArgs = {
  input: SendInvitationInput;
};


export type MutationJoinOrganizationArgs = {
  input: JoinOrganizationInput;
};


export type MutationAssignTeamArgs = {
  input: AssignTeamInput;
};


export type MutationDeleteUsersArgs = {
  input: DeleteUsersInput;
};


export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUploadFileArgs = {
  filename: Scalars['String'];
  file: Scalars['Upload'];
};


export type MutationCreateChallengeArgs = {
  input: ChallengeInput;
};


export type MutationUpdateChallengeArgs = {
  input: UpdateChallengeInput;
};


export type MutationDeleteChallengeArgs = {
  input: IdInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationDeleteTeamsArgs = {
  input: DeleteTeamsInput;
};

export type CreateAttemptInput = {
  challengeId: Scalars['Float'];
  description: Scalars['String'];
  createdAt: Scalars['Float'];
  file?: Maybe<Scalars['String']>;
};

export type UpdateAttemptInput = {
  id: Scalars['Float'];
  description: Scalars['String'];
};

export type IdInput = {
  id: Scalars['Float'];
};

export type RegisterInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  organization: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
};

export type LoginInput = {
  emailOrUsername: Scalars['String'];
  password: Scalars['String'];
};

export type SendInvitationInput = {
  to: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type JoinOrganizationInput = {
  key: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type AssignTeamInput = {
  userIds: Array<Scalars['Int']>;
  teamId: Scalars['Int'];
};

export type DeleteUsersInput = {
  userIds: Array<Scalars['Int']>;
};

export type UpdateMeInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  language?: Maybe<Language>;
};

export type UpdateUserInput = {
  id: Scalars['Float'];
  roles?: Maybe<Array<Role>>;
};


export type ChallengeInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: ChallengeType;
  points: Scalars['Float'];
  pointsDifference?: Maybe<Scalars['Int']>;
  repeats?: Maybe<Scalars['Int']>;
  /** In days */
  repeatInterval?: Maybe<Scalars['Int']>;
  previousChallengeId?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
  deadline?: Maybe<Scalars['String']>;
  autoApprove?: Maybe<Scalars['Boolean']>;
};

export type UpdateChallengeInput = {
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  pointsDifference?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['String']>;
  autoApprove?: Maybe<Scalars['Boolean']>;
  repeats?: Maybe<Scalars['Int']>;
  /** In days */
  repeatInterval?: Maybe<Scalars['Int']>;
  previousChallengeId?: Maybe<Scalars['Int']>;
};

export type CreateTeamInput = {
  name: Scalars['String'];
};

export type DeleteTeamsInput = {
  ids: Array<Scalars['Int']>;
};

export type FileUpload = {
  __typename?: 'FileUpload';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type BasicAttemptFragment = (
  { __typename?: 'Attempt' }
  & Pick<Attempt, 'id' | 'description' | 'points' | 'approved' | 'rejected' | 'createdAt' | 'file'>
);

export type BasicChallengeFragment = (
  { __typename?: 'Challenge' }
  & Pick<Challenge, 'id' | 'name' | 'type' | 'description' | 'points' | 'autoApprove' | 'repeats' | 'repeatInterval' | 'startDate' | 'deadline'>
  & { previousChallenge?: Maybe<(
    { __typename?: 'Challenge' }
    & Pick<Challenge, 'id'>
  )> }
);

export type BasicOrganizationFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
);

export type BasicTeamFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name'>
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & BasicUserFragment
  )>> }
);

export type BasicUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'email' | 'language' | 'roles'>
);

export type UserTableRowFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'email' | 'roles' | 'validationHash'>
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  )> }
);

export type SetSuperOrganizationMutationVariables = Exact<{
  input: IdInput;
}>;


export type SetSuperOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setSuperOrganization'>
);

export type JoinOrganizationMutationVariables = Exact<{
  input: JoinOrganizationInput;
}>;


export type JoinOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { joinOrganization: (
    { __typename?: 'User' }
    & BasicUserFragment
  ) }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'User' }
    & BasicUserFragment
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'User' }
    & BasicUserFragment
  ) }
);

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;


export type UpdateMeMutation = (
  { __typename?: 'Mutation' }
  & { updateMe?: Maybe<(
    { __typename?: 'User' }
    & BasicUserFragment
  )> }
);

export type CreateAttemptMutationVariables = Exact<{
  input: CreateAttemptInput;
}>;


export type CreateAttemptMutation = (
  { __typename?: 'Mutation' }
  & { createAttempt: (
    { __typename?: 'Attempt' }
    & BasicAttemptFragment
  ) }
);

export type CreateChallengeMutationVariables = Exact<{
  input: ChallengeInput;
}>;


export type CreateChallengeMutation = (
  { __typename?: 'Mutation' }
  & { createChallenge: (
    { __typename?: 'Challenge' }
    & BasicChallengeFragment
  ) }
);

export type DeleteChallengeMutationVariables = Exact<{
  input: IdInput;
}>;


export type DeleteChallengeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteChallenge'>
);

export type ToggleAttemptApproveMutationVariables = Exact<{
  input: IdInput;
}>;


export type ToggleAttemptApproveMutation = (
  { __typename?: 'Mutation' }
  & { toggleAttemptApprove?: Maybe<(
    { __typename?: 'Attempt' }
    & BasicAttemptFragment
  )> }
);

export type ToggleAttemptRejectMutationVariables = Exact<{
  input: IdInput;
}>;


export type ToggleAttemptRejectMutation = (
  { __typename?: 'Mutation' }
  & { toggleAttemptReject?: Maybe<(
    { __typename?: 'Attempt' }
    & BasicAttemptFragment
  )> }
);

export type UpdateAttemptMutationVariables = Exact<{
  input: UpdateAttemptInput;
}>;


export type UpdateAttemptMutation = (
  { __typename?: 'Mutation' }
  & { updateAttempt: (
    { __typename?: 'Attempt' }
    & BasicAttemptFragment
  ) }
);

export type UpdateChallengeMutationVariables = Exact<{
  input: UpdateChallengeInput;
}>;


export type UpdateChallengeMutation = (
  { __typename?: 'Mutation' }
  & { updateChallenge?: Maybe<(
    { __typename?: 'Challenge' }
    & BasicChallengeFragment
  )> }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  filename: Scalars['String'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadFile'>
);

export type DeleteOrganizationMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrganization'>
);

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam: (
    { __typename?: 'Team' }
    & BasicTeamFragment
  ) }
);

export type DeleteTeamsMutationVariables = Exact<{
  input: DeleteTeamsInput;
}>;


export type DeleteTeamsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeams'>
);

export type AssignTeamMutationVariables = Exact<{
  input: AssignTeamInput;
}>;


export type AssignTeamMutation = (
  { __typename?: 'Mutation' }
  & { assignTeam: Array<(
    { __typename?: 'User' }
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )> }
    & BasicUserFragment
  )> }
);

export type DeleteUsersMutationVariables = Exact<{
  input: DeleteUsersInput;
}>;


export type DeleteUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUsers'>
);

export type SendInvitationMutationVariables = Exact<{
  input: SendInvitationInput;
}>;


export type SendInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendInvitation'>
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & BasicUserFragment
  )> }
);

export type GetChallengeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetChallengeQuery = (
  { __typename?: 'Query' }
  & { challenge?: Maybe<(
    { __typename?: 'Challenge' }
    & BasicChallengeFragment
  )> }
);

export type GetChallengesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<ChallengeOrder>;
  currentOnly?: Maybe<Scalars['Boolean']>;
  typeIn?: Maybe<Array<ChallengeType>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetChallengesQuery = (
  { __typename?: 'Query' }
  & { challenges?: Maybe<Array<(
    { __typename?: 'Challenge' }
    & BasicChallengeFragment
  )>> }
);

export type GetChallengesWithAttemptsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<ChallengeOrder>;
  currentOnly?: Maybe<Scalars['Boolean']>;
  typeIn?: Maybe<Array<ChallengeType>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetChallengesWithAttemptsQuery = (
  { __typename?: 'Query' }
  & { challenges?: Maybe<Array<(
    { __typename?: 'Challenge' }
    & { attempts?: Maybe<Array<(
      { __typename?: 'Attempt' }
      & { user: (
        { __typename?: 'User' }
        & BasicUserFragment
      ) }
      & BasicAttemptFragment
    )>> }
    & BasicChallengeFragment
  )>> }
);

export type GetMyAttemptsQueryVariables = Exact<{
  approved?: Maybe<Scalars['Boolean']>;
  rejected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  since?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetMyAttemptsQuery = (
  { __typename?: 'Query' }
  & { myAttempts: Array<(
    { __typename?: 'Attempt' }
    & { challenge: (
      { __typename?: 'Challenge' }
      & BasicChallengeFragment
    ) }
    & BasicAttemptFragment
  )> }
);

export type GetTeamAttemptsQueryVariables = Exact<{
  approved?: Maybe<Scalars['Boolean']>;
  rejected?: Maybe<Scalars['Boolean']>;
  challengeId?: Maybe<Scalars['Int']>;
}>;


export type GetTeamAttemptsQuery = (
  { __typename?: 'Query' }
  & { teamAttempts: Array<(
    { __typename?: 'Attempt' }
    & { challenge: (
      { __typename?: 'Challenge' }
      & BasicChallengeFragment
    ), user: (
      { __typename?: 'User' }
      & BasicUserFragment
    ) }
    & BasicAttemptFragment
  )> }
);

export type GetTypeChallengesQueryVariables = Exact<{
  type?: Maybe<ChallengeType>;
}>;


export type GetTypeChallengesQuery = (
  { __typename?: 'Query' }
  & { typeChallenges?: Maybe<Array<(
    { __typename?: 'Challenge' }
    & BasicChallengeFragment
  )>> }
);

export type GetFeedQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetFeedQuery = (
  { __typename?: 'Query' }
  & { feed: Array<(
    { __typename?: 'History' }
    & Pick<History, 'id' | 'entity' | 'operation' | 'timestamp'>
    & { user: (
      { __typename?: 'User' }
      & BasicUserFragment
    ), attempt: (
      { __typename?: 'Attempt' }
      & { challenge: (
        { __typename?: 'Challenge' }
        & Pick<Challenge, 'id' | 'name'>
      ) }
      & BasicAttemptFragment
    ) }
  )> }
);

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = (
  { __typename?: 'Query' }
  & { organizations: Array<(
    { __typename?: 'Organization' }
    & BasicOrganizationFragment
  )> }
);

export type GetTeamQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTeamQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'User' }
      & { attempts?: Maybe<Array<(
        { __typename?: 'Attempt' }
        & { challenge: (
          { __typename?: 'Challenge' }
          & Pick<Challenge, 'id' | 'points'>
        ) }
        & BasicAttemptFragment
      )>> }
      & BasicUserFragment
    )>> }
  )> }
);

export type GetTeamNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamNamesQuery = (
  { __typename?: 'Query' }
  & { teams: Array<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  )> }
);

export type GetTeamProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamProgressQuery = (
  { __typename?: 'Query' }
  & { teamProgress: Array<(
    { __typename?: 'Progress' }
    & { user: (
      { __typename?: 'User' }
      & BasicUserFragment
    ), data: Array<(
      { __typename?: 'Result' }
      & { x: Result['date'], y: Result['points'] }
    )> }
  )> }
);

export type GetTeamStatsQueryVariables = Exact<{
  teamId?: Maybe<Scalars['Int']>;
}>;


export type GetTeamStatsQuery = (
  { __typename?: 'Query' }
  & { teamStats?: Maybe<(
    { __typename?: 'TeamStats' }
    & Pick<TeamStats, 'points' | 'usersNumber' | 'organizationPosition' | 'approvedAttempts' | 'awaitingAttempts'>
  )> }
);

export type GetTeamsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type GetTeamsQuery = (
  { __typename?: 'Query' }
  & { teams: Array<(
    { __typename?: 'Team' }
    & BasicTeamFragment
  )> }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ) }
    & BasicUserFragment
  )> }
);

export type GetStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatsQuery = (
  { __typename?: 'Query' }
  & { stats?: Maybe<(
    { __typename?: 'Stats' }
    & Pick<Stats, 'points' | 'behind' | 'teamPosition' | 'organizationPosition' | 'approvedAttempts' | 'awaitingAttempts'>
  )> }
);

export type GetUsersQueryVariables = Exact<{
  input?: Maybe<UsersInput>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & UserTableRowFragment
  )> }
);

export const BasicAttemptFragmentDoc = gql`
    fragment BasicAttempt on Attempt {
  id
  description
  points
  approved
  rejected
  createdAt
  file
}
    `;
export const BasicChallengeFragmentDoc = gql`
    fragment BasicChallenge on Challenge {
  id
  name
  type
  description
  points
  autoApprove
  repeats
  repeatInterval
  previousChallenge {
    id
  }
  startDate
  deadline
}
    `;
export const BasicOrganizationFragmentDoc = gql`
    fragment BasicOrganization on Organization {
  id
  name
}
    `;
export const BasicUserFragmentDoc = gql`
    fragment BasicUser on User {
  id
  firstName
  lastName
  username
  email
  language
  roles
}
    `;
export const BasicTeamFragmentDoc = gql`
    fragment BasicTeam on Team {
  id
  name
  users {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;
export const UserTableRowFragmentDoc = gql`
    fragment UserTableRow on User {
  id
  firstName
  lastName
  username
  email
  roles
  validationHash
  team {
    id
    name
  }
}
    `;
export const SetSuperOrganizationDocument = gql`
    mutation setSuperOrganization($input: IdInput!) {
  setSuperOrganization(input: $input)
}
    `;
export type SetSuperOrganizationMutationFn = Apollo.MutationFunction<SetSuperOrganizationMutation, SetSuperOrganizationMutationVariables>;

/**
 * __useSetSuperOrganizationMutation__
 *
 * To run a mutation, you first call `useSetSuperOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSuperOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSuperOrganizationMutation, { data, loading, error }] = useSetSuperOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSuperOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SetSuperOrganizationMutation, SetSuperOrganizationMutationVariables>) {
        return Apollo.useMutation<SetSuperOrganizationMutation, SetSuperOrganizationMutationVariables>(SetSuperOrganizationDocument, baseOptions);
      }
export type SetSuperOrganizationMutationHookResult = ReturnType<typeof useSetSuperOrganizationMutation>;
export type SetSuperOrganizationMutationResult = Apollo.MutationResult<SetSuperOrganizationMutation>;
export type SetSuperOrganizationMutationOptions = Apollo.BaseMutationOptions<SetSuperOrganizationMutation, SetSuperOrganizationMutationVariables>;
export const JoinOrganizationDocument = gql`
    mutation joinOrganization($input: JoinOrganizationInput!) {
  joinOrganization(input: $input) {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;
export type JoinOrganizationMutationFn = Apollo.MutationFunction<JoinOrganizationMutation, JoinOrganizationMutationVariables>;

/**
 * __useJoinOrganizationMutation__
 *
 * To run a mutation, you first call `useJoinOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinOrganizationMutation, { data, loading, error }] = useJoinOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<JoinOrganizationMutation, JoinOrganizationMutationVariables>) {
        return Apollo.useMutation<JoinOrganizationMutation, JoinOrganizationMutationVariables>(JoinOrganizationDocument, baseOptions);
      }
export type JoinOrganizationMutationHookResult = ReturnType<typeof useJoinOrganizationMutation>;
export type JoinOrganizationMutationResult = Apollo.MutationResult<JoinOrganizationMutation>;
export type JoinOrganizationMutationOptions = Apollo.BaseMutationOptions<JoinOrganizationMutation, JoinOrganizationMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UpdateMeDocument = gql`
    mutation updateMe($input: UpdateMeInput!) {
  updateMe(input: $input) {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, baseOptions);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const CreateAttemptDocument = gql`
    mutation createAttempt($input: CreateAttemptInput!) {
  createAttempt(input: $input) {
    ...BasicAttempt
  }
}
    ${BasicAttemptFragmentDoc}`;
export type CreateAttemptMutationFn = Apollo.MutationFunction<CreateAttemptMutation, CreateAttemptMutationVariables>;

/**
 * __useCreateAttemptMutation__
 *
 * To run a mutation, you first call `useCreateAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttemptMutation, { data, loading, error }] = useCreateAttemptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttemptMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttemptMutation, CreateAttemptMutationVariables>) {
        return Apollo.useMutation<CreateAttemptMutation, CreateAttemptMutationVariables>(CreateAttemptDocument, baseOptions);
      }
export type CreateAttemptMutationHookResult = ReturnType<typeof useCreateAttemptMutation>;
export type CreateAttemptMutationResult = Apollo.MutationResult<CreateAttemptMutation>;
export type CreateAttemptMutationOptions = Apollo.BaseMutationOptions<CreateAttemptMutation, CreateAttemptMutationVariables>;
export const CreateChallengeDocument = gql`
    mutation createChallenge($input: ChallengeInput!) {
  createChallenge(input: $input) {
    ...BasicChallenge
  }
}
    ${BasicChallengeFragmentDoc}`;
export type CreateChallengeMutationFn = Apollo.MutationFunction<CreateChallengeMutation, CreateChallengeMutationVariables>;

/**
 * __useCreateChallengeMutation__
 *
 * To run a mutation, you first call `useCreateChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChallengeMutation, { data, loading, error }] = useCreateChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChallengeMutation(baseOptions?: Apollo.MutationHookOptions<CreateChallengeMutation, CreateChallengeMutationVariables>) {
        return Apollo.useMutation<CreateChallengeMutation, CreateChallengeMutationVariables>(CreateChallengeDocument, baseOptions);
      }
export type CreateChallengeMutationHookResult = ReturnType<typeof useCreateChallengeMutation>;
export type CreateChallengeMutationResult = Apollo.MutationResult<CreateChallengeMutation>;
export type CreateChallengeMutationOptions = Apollo.BaseMutationOptions<CreateChallengeMutation, CreateChallengeMutationVariables>;
export const DeleteChallengeDocument = gql`
    mutation deleteChallenge($input: IdInput!) {
  deleteChallenge(input: $input)
}
    `;
export type DeleteChallengeMutationFn = Apollo.MutationFunction<DeleteChallengeMutation, DeleteChallengeMutationVariables>;

/**
 * __useDeleteChallengeMutation__
 *
 * To run a mutation, you first call `useDeleteChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChallengeMutation, { data, loading, error }] = useDeleteChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChallengeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChallengeMutation, DeleteChallengeMutationVariables>) {
        return Apollo.useMutation<DeleteChallengeMutation, DeleteChallengeMutationVariables>(DeleteChallengeDocument, baseOptions);
      }
export type DeleteChallengeMutationHookResult = ReturnType<typeof useDeleteChallengeMutation>;
export type DeleteChallengeMutationResult = Apollo.MutationResult<DeleteChallengeMutation>;
export type DeleteChallengeMutationOptions = Apollo.BaseMutationOptions<DeleteChallengeMutation, DeleteChallengeMutationVariables>;
export const ToggleAttemptApproveDocument = gql`
    mutation toggleAttemptApprove($input: IdInput!) {
  toggleAttemptApprove(input: $input) {
    ...BasicAttempt
  }
}
    ${BasicAttemptFragmentDoc}`;
export type ToggleAttemptApproveMutationFn = Apollo.MutationFunction<ToggleAttemptApproveMutation, ToggleAttemptApproveMutationVariables>;

/**
 * __useToggleAttemptApproveMutation__
 *
 * To run a mutation, you first call `useToggleAttemptApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAttemptApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAttemptApproveMutation, { data, loading, error }] = useToggleAttemptApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleAttemptApproveMutation(baseOptions?: Apollo.MutationHookOptions<ToggleAttemptApproveMutation, ToggleAttemptApproveMutationVariables>) {
        return Apollo.useMutation<ToggleAttemptApproveMutation, ToggleAttemptApproveMutationVariables>(ToggleAttemptApproveDocument, baseOptions);
      }
export type ToggleAttemptApproveMutationHookResult = ReturnType<typeof useToggleAttemptApproveMutation>;
export type ToggleAttemptApproveMutationResult = Apollo.MutationResult<ToggleAttemptApproveMutation>;
export type ToggleAttemptApproveMutationOptions = Apollo.BaseMutationOptions<ToggleAttemptApproveMutation, ToggleAttemptApproveMutationVariables>;
export const ToggleAttemptRejectDocument = gql`
    mutation toggleAttemptReject($input: IdInput!) {
  toggleAttemptReject(input: $input) {
    ...BasicAttempt
  }
}
    ${BasicAttemptFragmentDoc}`;
export type ToggleAttemptRejectMutationFn = Apollo.MutationFunction<ToggleAttemptRejectMutation, ToggleAttemptRejectMutationVariables>;

/**
 * __useToggleAttemptRejectMutation__
 *
 * To run a mutation, you first call `useToggleAttemptRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAttemptRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAttemptRejectMutation, { data, loading, error }] = useToggleAttemptRejectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleAttemptRejectMutation(baseOptions?: Apollo.MutationHookOptions<ToggleAttemptRejectMutation, ToggleAttemptRejectMutationVariables>) {
        return Apollo.useMutation<ToggleAttemptRejectMutation, ToggleAttemptRejectMutationVariables>(ToggleAttemptRejectDocument, baseOptions);
      }
export type ToggleAttemptRejectMutationHookResult = ReturnType<typeof useToggleAttemptRejectMutation>;
export type ToggleAttemptRejectMutationResult = Apollo.MutationResult<ToggleAttemptRejectMutation>;
export type ToggleAttemptRejectMutationOptions = Apollo.BaseMutationOptions<ToggleAttemptRejectMutation, ToggleAttemptRejectMutationVariables>;
export const UpdateAttemptDocument = gql`
    mutation updateAttempt($input: UpdateAttemptInput!) {
  updateAttempt(input: $input) {
    ...BasicAttempt
  }
}
    ${BasicAttemptFragmentDoc}`;
export type UpdateAttemptMutationFn = Apollo.MutationFunction<UpdateAttemptMutation, UpdateAttemptMutationVariables>;

/**
 * __useUpdateAttemptMutation__
 *
 * To run a mutation, you first call `useUpdateAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttemptMutation, { data, loading, error }] = useUpdateAttemptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttemptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttemptMutation, UpdateAttemptMutationVariables>) {
        return Apollo.useMutation<UpdateAttemptMutation, UpdateAttemptMutationVariables>(UpdateAttemptDocument, baseOptions);
      }
export type UpdateAttemptMutationHookResult = ReturnType<typeof useUpdateAttemptMutation>;
export type UpdateAttemptMutationResult = Apollo.MutationResult<UpdateAttemptMutation>;
export type UpdateAttemptMutationOptions = Apollo.BaseMutationOptions<UpdateAttemptMutation, UpdateAttemptMutationVariables>;
export const UpdateChallengeDocument = gql`
    mutation updateChallenge($input: UpdateChallengeInput!) {
  updateChallenge(input: $input) {
    ...BasicChallenge
  }
}
    ${BasicChallengeFragmentDoc}`;
export type UpdateChallengeMutationFn = Apollo.MutationFunction<UpdateChallengeMutation, UpdateChallengeMutationVariables>;

/**
 * __useUpdateChallengeMutation__
 *
 * To run a mutation, you first call `useUpdateChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChallengeMutation, { data, loading, error }] = useUpdateChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChallengeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChallengeMutation, UpdateChallengeMutationVariables>) {
        return Apollo.useMutation<UpdateChallengeMutation, UpdateChallengeMutationVariables>(UpdateChallengeDocument, baseOptions);
      }
export type UpdateChallengeMutationHookResult = ReturnType<typeof useUpdateChallengeMutation>;
export type UpdateChallengeMutationResult = Apollo.MutationResult<UpdateChallengeMutation>;
export type UpdateChallengeMutationOptions = Apollo.BaseMutationOptions<UpdateChallengeMutation, UpdateChallengeMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!, $filename: String!) {
  uploadFile(file: $file, filename: $filename)
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization {
  deleteOrganization
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, baseOptions);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    ...BasicTeam
  }
}
    ${BasicTeamFragmentDoc}`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, baseOptions);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const DeleteTeamsDocument = gql`
    mutation deleteTeams($input: DeleteTeamsInput!) {
  deleteTeams(input: $input)
}
    `;
export type DeleteTeamsMutationFn = Apollo.MutationFunction<DeleteTeamsMutation, DeleteTeamsMutationVariables>;

/**
 * __useDeleteTeamsMutation__
 *
 * To run a mutation, you first call `useDeleteTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamsMutation, { data, loading, error }] = useDeleteTeamsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTeamsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamsMutation, DeleteTeamsMutationVariables>) {
        return Apollo.useMutation<DeleteTeamsMutation, DeleteTeamsMutationVariables>(DeleteTeamsDocument, baseOptions);
      }
export type DeleteTeamsMutationHookResult = ReturnType<typeof useDeleteTeamsMutation>;
export type DeleteTeamsMutationResult = Apollo.MutationResult<DeleteTeamsMutation>;
export type DeleteTeamsMutationOptions = Apollo.BaseMutationOptions<DeleteTeamsMutation, DeleteTeamsMutationVariables>;
export const AssignTeamDocument = gql`
    mutation assignTeam($input: AssignTeamInput!) {
  assignTeam(input: $input) {
    ...BasicUser
    team {
      id
      name
    }
  }
}
    ${BasicUserFragmentDoc}`;
export type AssignTeamMutationFn = Apollo.MutationFunction<AssignTeamMutation, AssignTeamMutationVariables>;

/**
 * __useAssignTeamMutation__
 *
 * To run a mutation, you first call `useAssignTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTeamMutation, { data, loading, error }] = useAssignTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignTeamMutation(baseOptions?: Apollo.MutationHookOptions<AssignTeamMutation, AssignTeamMutationVariables>) {
        return Apollo.useMutation<AssignTeamMutation, AssignTeamMutationVariables>(AssignTeamDocument, baseOptions);
      }
export type AssignTeamMutationHookResult = ReturnType<typeof useAssignTeamMutation>;
export type AssignTeamMutationResult = Apollo.MutationResult<AssignTeamMutation>;
export type AssignTeamMutationOptions = Apollo.BaseMutationOptions<AssignTeamMutation, AssignTeamMutationVariables>;
export const DeleteUsersDocument = gql`
    mutation deleteUsers($input: DeleteUsersInput!) {
  deleteUsers(input: $input)
}
    `;
export type DeleteUsersMutationFn = Apollo.MutationFunction<DeleteUsersMutation, DeleteUsersMutationVariables>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersMutation, DeleteUsersMutationVariables>) {
        return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument, baseOptions);
      }
export type DeleteUsersMutationHookResult = ReturnType<typeof useDeleteUsersMutation>;
export type DeleteUsersMutationResult = Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<DeleteUsersMutation, DeleteUsersMutationVariables>;
export const SendInvitationDocument = gql`
    mutation sendInvitation($input: SendInvitationInput!) {
  sendInvitation(input: $input)
}
    `;
export type SendInvitationMutationFn = Apollo.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>) {
        return Apollo.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, baseOptions);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<SendInvitationMutation, SendInvitationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetChallengeDocument = gql`
    query getChallenge($id: Int!) {
  challenge(id: $id) {
    ...BasicChallenge
  }
}
    ${BasicChallengeFragmentDoc}`;

/**
 * __useGetChallengeQuery__
 *
 * To run a query within a React component, call `useGetChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChallengeQuery(baseOptions?: Apollo.QueryHookOptions<GetChallengeQuery, GetChallengeQueryVariables>) {
        return Apollo.useQuery<GetChallengeQuery, GetChallengeQueryVariables>(GetChallengeDocument, baseOptions);
      }
export function useGetChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChallengeQuery, GetChallengeQueryVariables>) {
          return Apollo.useLazyQuery<GetChallengeQuery, GetChallengeQueryVariables>(GetChallengeDocument, baseOptions);
        }
export type GetChallengeQueryHookResult = ReturnType<typeof useGetChallengeQuery>;
export type GetChallengeLazyQueryHookResult = ReturnType<typeof useGetChallengeLazyQuery>;
export type GetChallengeQueryResult = Apollo.QueryResult<GetChallengeQuery, GetChallengeQueryVariables>;
export const GetChallengesDocument = gql`
    query getChallenges($search: String, $orderBy: ChallengeOrder, $currentOnly: Boolean, $typeIn: [ChallengeType!], $offset: Int, $limit: Int) {
  challenges(search: $search, orderBy: $orderBy, typeIn: $typeIn, currentOnly: $currentOnly, offset: $offset, limit: $limit) {
    ...BasicChallenge
  }
}
    ${BasicChallengeFragmentDoc}`;

/**
 * __useGetChallengesQuery__
 *
 * To run a query within a React component, call `useGetChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      currentOnly: // value for 'currentOnly'
 *      typeIn: // value for 'typeIn'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetChallengesQuery(baseOptions?: Apollo.QueryHookOptions<GetChallengesQuery, GetChallengesQueryVariables>) {
        return Apollo.useQuery<GetChallengesQuery, GetChallengesQueryVariables>(GetChallengesDocument, baseOptions);
      }
export function useGetChallengesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChallengesQuery, GetChallengesQueryVariables>) {
          return Apollo.useLazyQuery<GetChallengesQuery, GetChallengesQueryVariables>(GetChallengesDocument, baseOptions);
        }
export type GetChallengesQueryHookResult = ReturnType<typeof useGetChallengesQuery>;
export type GetChallengesLazyQueryHookResult = ReturnType<typeof useGetChallengesLazyQuery>;
export type GetChallengesQueryResult = Apollo.QueryResult<GetChallengesQuery, GetChallengesQueryVariables>;
export const GetChallengesWithAttemptsDocument = gql`
    query getChallengesWithAttempts($search: String, $orderBy: ChallengeOrder, $currentOnly: Boolean, $typeIn: [ChallengeType!], $offset: Int, $limit: Int) {
  challenges(search: $search, orderBy: $orderBy, typeIn: $typeIn, currentOnly: $currentOnly, offset: $offset, limit: $limit) {
    ...BasicChallenge
    attempts {
      ...BasicAttempt
      user {
        ...BasicUser
      }
    }
  }
}
    ${BasicChallengeFragmentDoc}
${BasicAttemptFragmentDoc}
${BasicUserFragmentDoc}`;

/**
 * __useGetChallengesWithAttemptsQuery__
 *
 * To run a query within a React component, call `useGetChallengesWithAttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesWithAttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesWithAttemptsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      currentOnly: // value for 'currentOnly'
 *      typeIn: // value for 'typeIn'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetChallengesWithAttemptsQuery(baseOptions?: Apollo.QueryHookOptions<GetChallengesWithAttemptsQuery, GetChallengesWithAttemptsQueryVariables>) {
        return Apollo.useQuery<GetChallengesWithAttemptsQuery, GetChallengesWithAttemptsQueryVariables>(GetChallengesWithAttemptsDocument, baseOptions);
      }
export function useGetChallengesWithAttemptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChallengesWithAttemptsQuery, GetChallengesWithAttemptsQueryVariables>) {
          return Apollo.useLazyQuery<GetChallengesWithAttemptsQuery, GetChallengesWithAttemptsQueryVariables>(GetChallengesWithAttemptsDocument, baseOptions);
        }
export type GetChallengesWithAttemptsQueryHookResult = ReturnType<typeof useGetChallengesWithAttemptsQuery>;
export type GetChallengesWithAttemptsLazyQueryHookResult = ReturnType<typeof useGetChallengesWithAttemptsLazyQuery>;
export type GetChallengesWithAttemptsQueryResult = Apollo.QueryResult<GetChallengesWithAttemptsQuery, GetChallengesWithAttemptsQueryVariables>;
export const GetMyAttemptsDocument = gql`
    query getMyAttempts($approved: Boolean, $rejected: Boolean, $id: Int, $since: Int, $offset: Int, $limit: Int) {
  myAttempts(approved: $approved, rejected: $rejected, id: $id, since: $since, offset: $offset, limit: $limit) {
    ...BasicAttempt
    challenge {
      ...BasicChallenge
    }
  }
}
    ${BasicAttemptFragmentDoc}
${BasicChallengeFragmentDoc}`;

/**
 * __useGetMyAttemptsQuery__
 *
 * To run a query within a React component, call `useGetMyAttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAttemptsQuery({
 *   variables: {
 *      approved: // value for 'approved'
 *      rejected: // value for 'rejected'
 *      id: // value for 'id'
 *      since: // value for 'since'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMyAttemptsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyAttemptsQuery, GetMyAttemptsQueryVariables>) {
        return Apollo.useQuery<GetMyAttemptsQuery, GetMyAttemptsQueryVariables>(GetMyAttemptsDocument, baseOptions);
      }
export function useGetMyAttemptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAttemptsQuery, GetMyAttemptsQueryVariables>) {
          return Apollo.useLazyQuery<GetMyAttemptsQuery, GetMyAttemptsQueryVariables>(GetMyAttemptsDocument, baseOptions);
        }
export type GetMyAttemptsQueryHookResult = ReturnType<typeof useGetMyAttemptsQuery>;
export type GetMyAttemptsLazyQueryHookResult = ReturnType<typeof useGetMyAttemptsLazyQuery>;
export type GetMyAttemptsQueryResult = Apollo.QueryResult<GetMyAttemptsQuery, GetMyAttemptsQueryVariables>;
export const GetTeamAttemptsDocument = gql`
    query getTeamAttempts($approved: Boolean, $rejected: Boolean, $challengeId: Int) {
  teamAttempts(approved: $approved, rejected: $rejected, challengeId: $challengeId) {
    ...BasicAttempt
    challenge {
      ...BasicChallenge
    }
    user {
      ...BasicUser
    }
  }
}
    ${BasicAttemptFragmentDoc}
${BasicChallengeFragmentDoc}
${BasicUserFragmentDoc}`;

/**
 * __useGetTeamAttemptsQuery__
 *
 * To run a query within a React component, call `useGetTeamAttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamAttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamAttemptsQuery({
 *   variables: {
 *      approved: // value for 'approved'
 *      rejected: // value for 'rejected'
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useGetTeamAttemptsQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamAttemptsQuery, GetTeamAttemptsQueryVariables>) {
        return Apollo.useQuery<GetTeamAttemptsQuery, GetTeamAttemptsQueryVariables>(GetTeamAttemptsDocument, baseOptions);
      }
export function useGetTeamAttemptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamAttemptsQuery, GetTeamAttemptsQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamAttemptsQuery, GetTeamAttemptsQueryVariables>(GetTeamAttemptsDocument, baseOptions);
        }
export type GetTeamAttemptsQueryHookResult = ReturnType<typeof useGetTeamAttemptsQuery>;
export type GetTeamAttemptsLazyQueryHookResult = ReturnType<typeof useGetTeamAttemptsLazyQuery>;
export type GetTeamAttemptsQueryResult = Apollo.QueryResult<GetTeamAttemptsQuery, GetTeamAttemptsQueryVariables>;
export const GetTypeChallengesDocument = gql`
    query getTypeChallenges($type: ChallengeType) {
  typeChallenges(type: $type) {
    ...BasicChallenge
  }
}
    ${BasicChallengeFragmentDoc}`;

/**
 * __useGetTypeChallengesQuery__
 *
 * To run a query within a React component, call `useGetTypeChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypeChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTypeChallengesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetTypeChallengesQuery(baseOptions?: Apollo.QueryHookOptions<GetTypeChallengesQuery, GetTypeChallengesQueryVariables>) {
        return Apollo.useQuery<GetTypeChallengesQuery, GetTypeChallengesQueryVariables>(GetTypeChallengesDocument, baseOptions);
      }
export function useGetTypeChallengesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTypeChallengesQuery, GetTypeChallengesQueryVariables>) {
          return Apollo.useLazyQuery<GetTypeChallengesQuery, GetTypeChallengesQueryVariables>(GetTypeChallengesDocument, baseOptions);
        }
export type GetTypeChallengesQueryHookResult = ReturnType<typeof useGetTypeChallengesQuery>;
export type GetTypeChallengesLazyQueryHookResult = ReturnType<typeof useGetTypeChallengesLazyQuery>;
export type GetTypeChallengesQueryResult = Apollo.QueryResult<GetTypeChallengesQuery, GetTypeChallengesQueryVariables>;
export const GetFeedDocument = gql`
    query getFeed($limit: Int, $offset: Int) {
  feed(limit: $limit, offset: $offset) {
    id
    entity
    operation
    timestamp
    user {
      ...BasicUser
    }
    attempt {
      ...BasicAttempt
      challenge {
        id
        name
      }
    }
  }
}
    ${BasicUserFragmentDoc}
${BasicAttemptFragmentDoc}`;

/**
 * __useGetFeedQuery__
 *
 * To run a query within a React component, call `useGetFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetFeedQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedQuery, GetFeedQueryVariables>) {
        return Apollo.useQuery<GetFeedQuery, GetFeedQueryVariables>(GetFeedDocument, baseOptions);
      }
export function useGetFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedQuery, GetFeedQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedQuery, GetFeedQueryVariables>(GetFeedDocument, baseOptions);
        }
export type GetFeedQueryHookResult = ReturnType<typeof useGetFeedQuery>;
export type GetFeedLazyQueryHookResult = ReturnType<typeof useGetFeedLazyQuery>;
export type GetFeedQueryResult = Apollo.QueryResult<GetFeedQuery, GetFeedQueryVariables>;
export const GetOrganizationsDocument = gql`
    query getOrganizations {
  organizations {
    ...BasicOrganization
  }
}
    ${BasicOrganizationFragmentDoc}`;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, baseOptions);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, baseOptions);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetTeamDocument = gql`
    query getTeam($id: Int!) {
  team(id: $id) {
    id
    name
    users {
      ...BasicUser
      attempts {
        ...BasicAttempt
        challenge {
          id
          points
        }
      }
    }
  }
}
    ${BasicUserFragmentDoc}
${BasicAttemptFragmentDoc}`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
        return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, baseOptions);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, baseOptions);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const GetTeamNamesDocument = gql`
    query getTeamNames {
  teams {
    id
    name
  }
}
    `;

/**
 * __useGetTeamNamesQuery__
 *
 * To run a query within a React component, call `useGetTeamNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamNamesQuery, GetTeamNamesQueryVariables>) {
        return Apollo.useQuery<GetTeamNamesQuery, GetTeamNamesQueryVariables>(GetTeamNamesDocument, baseOptions);
      }
export function useGetTeamNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamNamesQuery, GetTeamNamesQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamNamesQuery, GetTeamNamesQueryVariables>(GetTeamNamesDocument, baseOptions);
        }
export type GetTeamNamesQueryHookResult = ReturnType<typeof useGetTeamNamesQuery>;
export type GetTeamNamesLazyQueryHookResult = ReturnType<typeof useGetTeamNamesLazyQuery>;
export type GetTeamNamesQueryResult = Apollo.QueryResult<GetTeamNamesQuery, GetTeamNamesQueryVariables>;
export const GetTeamProgressDocument = gql`
    query getTeamProgress {
  teamProgress {
    user {
      ...BasicUser
    }
    data: results {
      x: date
      y: points
    }
  }
}
    ${BasicUserFragmentDoc}`;

/**
 * __useGetTeamProgressQuery__
 *
 * To run a query within a React component, call `useGetTeamProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamProgressQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamProgressQuery, GetTeamProgressQueryVariables>) {
        return Apollo.useQuery<GetTeamProgressQuery, GetTeamProgressQueryVariables>(GetTeamProgressDocument, baseOptions);
      }
export function useGetTeamProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamProgressQuery, GetTeamProgressQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamProgressQuery, GetTeamProgressQueryVariables>(GetTeamProgressDocument, baseOptions);
        }
export type GetTeamProgressQueryHookResult = ReturnType<typeof useGetTeamProgressQuery>;
export type GetTeamProgressLazyQueryHookResult = ReturnType<typeof useGetTeamProgressLazyQuery>;
export type GetTeamProgressQueryResult = Apollo.QueryResult<GetTeamProgressQuery, GetTeamProgressQueryVariables>;
export const GetTeamStatsDocument = gql`
    query getTeamStats($teamId: Int) {
  teamStats(teamId: $teamId) {
    points
    usersNumber
    organizationPosition
    approvedAttempts
    awaitingAttempts
  }
}
    `;

/**
 * __useGetTeamStatsQuery__
 *
 * To run a query within a React component, call `useGetTeamStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamStatsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamStatsQuery, GetTeamStatsQueryVariables>) {
        return Apollo.useQuery<GetTeamStatsQuery, GetTeamStatsQueryVariables>(GetTeamStatsDocument, baseOptions);
      }
export function useGetTeamStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamStatsQuery, GetTeamStatsQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamStatsQuery, GetTeamStatsQueryVariables>(GetTeamStatsDocument, baseOptions);
        }
export type GetTeamStatsQueryHookResult = ReturnType<typeof useGetTeamStatsQuery>;
export type GetTeamStatsLazyQueryHookResult = ReturnType<typeof useGetTeamStatsLazyQuery>;
export type GetTeamStatsQueryResult = Apollo.QueryResult<GetTeamStatsQuery, GetTeamStatsQueryVariables>;
export const GetTeamsDocument = gql`
    query getTeams($search: String) {
  teams(search: $search) {
    ...BasicTeam
  }
}
    ${BasicTeamFragmentDoc}`;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetTeamsQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>) {
        return Apollo.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, baseOptions);
      }
export function useGetTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, baseOptions);
        }
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<typeof useGetTeamsLazyQuery>;
export type GetTeamsQueryResult = Apollo.QueryResult<GetTeamsQuery, GetTeamsQueryVariables>;
export const GetMeDocument = gql`
    query getMe {
  me {
    ...BasicUser
    team {
      id
      name
    }
    organization {
      id
      name
    }
  }
}
    ${BasicUserFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetStatsDocument = gql`
    query getStats {
  stats {
    points
    behind
    teamPosition
    organizationPosition
    approvedAttempts
    awaitingAttempts
  }
}
    `;

/**
 * __useGetStatsQuery__
 *
 * To run a query within a React component, call `useGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
        return Apollo.useQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, baseOptions);
      }
export function useGetStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
          return Apollo.useLazyQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, baseOptions);
        }
export type GetStatsQueryHookResult = ReturnType<typeof useGetStatsQuery>;
export type GetStatsLazyQueryHookResult = ReturnType<typeof useGetStatsLazyQuery>;
export type GetStatsQueryResult = Apollo.QueryResult<GetStatsQuery, GetStatsQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($input: UsersInput) {
  users(input: $input) {
    ...UserTableRow
  }
}
    ${UserTableRowFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;