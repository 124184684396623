import { ROUTE_TABS, RouteEnum, getRoutePath } from '../helpers/routing';
import { useCurrentUser } from './useCurrentUser';

export const useRedirect = (
  route: RouteEnum,
  currentTab: string,
  base?: string,
): {
  redirect?: string;
  loading: boolean;
} => {
  const tabs = ROUTE_TABS[route];
  const { user, loading } = useCurrentUser();
  const path = getRoutePath(route);
  const keys = Object.keys(tabs);

  if (loading) {
    return { loading };
  }

  if (!keys.includes(currentTab) || !user) {
    return {
      redirect: '/',
      loading: false,
    };
  }

  if (
    tabs[currentTab].only &&
    !tabs[currentTab].only?.some((role) => user.roles.includes(role))
  ) {
    return {
      redirect: [path, base].filter(Boolean).join('/'),
      loading: false,
    };
  }

  return { loading: false };
};
