import React from 'react';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
import { Navigation } from './Navigation';
import { Menu } from './Menu';

const StyledLayout = styled(AntLayout)`
  height: 100vh;
`;

const Container = styled(AntLayout.Content)`
  height: 100%;
  padding: 24px;

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export const Layout: React.FC = ({ children }) => {
  return (
    <StyledLayout>
      <AntLayout>
        <Navigation />
        <Menu />
        <Container>{children}</Container>
      </AntLayout>
    </StyledLayout>
  );
};
