import React from 'react';
import { Route, useLocation, Redirect } from 'react-router-dom';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { Layout } from 'src/components/UI/Layout';
import { useCurrentUser } from 'src/utils/hooks';
import {
  RouteEnum,
  getAvailablesRoutes,
  RouteContext,
} from 'src/utils/helpers/routing';

interface PrivateRouteProps {
  path: string | string[];
  component: React.FC;
  entity: RouteEnum;
  exact?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  component,
  entity,
  exact = false,
}) => {
  const location = useLocation();
  const { user, loading } = useCurrentUser();

  if (loading) {
    return null;
  }

  if (!user?.id && !loading) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  const availableRoutes = getAvailablesRoutes(user?.roles);

  if (!availableRoutes.includes(entity)) {
    return <Redirect to="/" />;
  }

  return (
    <ErrorBoundary>
      <RouteContext.Provider value={entity}>
        <Layout>
          <Route path={path} component={component} exact={exact} />
        </Layout>
      </RouteContext.Provider>
    </ErrorBoundary>
  );
};
