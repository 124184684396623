import {
  MutationUpdaterFn,
  MutationFunctionOptions,
  FetchResult,
} from '@apollo/client';
import { GraphQLError } from 'graphql';
import get from 'lodash/get';
import { Exact } from './generated';

interface MutationOutcome {
  successCallback?: () => void;
  errorCallback?: (error?: GraphQLError) => void;
}

type MutatorOptions<Mutation> = MutationOutcome & {
  mutation: (
    options?: MutationFunctionOptions<Mutation, any> | undefined,
  ) => Promise<FetchResult<Mutation, Record<string, any>, Record<string, any>>>;
  update?: MutationUpdaterFn;
};

export const createMutator = <Mutation, MutationInputType = {}>({
  mutation,
  update,
  successCallback,
  errorCallback,
}: MutatorOptions<Mutation>) => async (
  input?: Exact<{ input: MutationInputType }>,
): Promise<Mutation | undefined | null> => {
  try {
    const { data } = await mutation({
      variables: input,
      update,
    });

    if (successCallback) {
      successCallback();
    }
    return data;
  } catch (error) {
    if (errorCallback) {
      const gqlErrors = get(error, 'graphQLErrors', []);
      errorCallback(gqlErrors[0]);
    }
  }
};
