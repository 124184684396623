export const BACKGROUND_COLOR = '#f0f2f5';
export const WHITE = '#fff';
export const BLACK = '#222424';
export const BORDER_COLOR = '#d9d9d9';
export const DIVIDER_COLOR = '#f0f0f0';
export const DISABLE_COLOR = '#bfbfbf';

export const RED_SALSA = '#F94144';
export const ORANGE_RED = '#F3722C';
export const LIGHTNING_YELLOW = '#F8961E';
export const MAIZE_CRAYOLA_YELLOW = '#F9C74F';
export const PISTACHIO_GREEN = '#90BE6D';
export const ZOMP_BLUE = '#43AA8B';
export const QUEEN_BLUE = '#577590';
export const CHARCOAL = '#264653';

export const PRIMARY_COLOR = ZOMP_BLUE;
export const SECONDARY_COLOR = LIGHTNING_YELLOW;
export const TEXT_COLOR = CHARCOAL;

export const ONE_TIME_CHALLENGE_COLOR = ORANGE_RED;
export const REPEATABLE_CHALLENGE_COLOR = LIGHTNING_YELLOW;
export const FIRST_COME_FIRST_SERVED_CHALLENGE_COLOR = MAIZE_CRAYOLA_YELLOW;
export const CHAIN_CHALLENGE_COLOR = PISTACHIO_GREEN;
export const QUESTION_AND_VOTE_CHALLENGE_COLOR = ZOMP_BLUE;
