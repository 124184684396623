import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://integrigo.app'
    : process.env.PUBLIC_URL;

const link = (createUploadLink({
  uri: `${API_URL}/graphql`,
  credentials: 'include',
}) as unknown) as ApolloLink;

export const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache(),
  link,
});
