import React, { ReactNode } from 'react';
import {
  HomeOutlined,
  TeamOutlined,
  SettingOutlined,
  DeploymentUnitOutlined,
  BuildOutlined,
} from '@ant-design/icons';
import uniq from 'lodash/uniq';
import { Role } from '../graphql';

export enum RouteEnum {
  DASHBOARD = 'dashboard',
  USERS = 'users',
  TEAMS = 'teams',
  CHALLENGES = 'challenges',
  SETTINGS = 'settings',
  ADMIN = 'admin',
}

export const RouteContext = React.createContext(RouteEnum.DASHBOARD);

export const getRouteIcon = (route: RouteEnum): ReactNode => {
  switch (route) {
    case RouteEnum.DASHBOARD:
      return <HomeOutlined />;
    case RouteEnum.USERS:
      return <TeamOutlined />;
    case RouteEnum.TEAMS:
      return <DeploymentUnitOutlined />;
    case RouteEnum.CHALLENGES:
      return <BuildOutlined />;
    case RouteEnum.SETTINGS:
      return <SettingOutlined />;
    default:
      return null;
  }
};

export const getRoutePath = (route: RouteEnum): string => {
  switch (route) {
    case RouteEnum.USERS:
      return '/users';
    case RouteEnum.TEAMS:
      return '/teams';
    case RouteEnum.CHALLENGES:
      return '/challenges';
    case RouteEnum.SETTINGS:
      return '/settings';
    case RouteEnum.ADMIN:
      return '/admin';
    default:
      return '/';
  }
};

export const getAvailablesRoutes = (roles = [] as Role[]): RouteEnum[] => {
  const adminRoutes: RouteEnum[] = roles.includes(Role.Admin)
    ? [RouteEnum.USERS, RouteEnum.TEAMS]
    : [];

  const managerRoutes: RouteEnum[] = roles.includes(Role.Manager)
    ? [RouteEnum.TEAMS]
    : [];

  const superuserRoutes: RouteEnum[] = roles.includes(Role.Superuser)
    ? [RouteEnum.ADMIN]
    : [];

  const managementRoutes = uniq([...adminRoutes, ...managerRoutes]);

  return [
    RouteEnum.DASHBOARD,
    ...managementRoutes,
    RouteEnum.CHALLENGES,
    RouteEnum.SETTINGS,
    ...superuserRoutes,
  ];
};

export const ROUTE_TABS: Record<
  RouteEnum,
  Record<string, { path: string; only?: Role[]; invisible?: boolean }>
> = {
  [RouteEnum.CHALLENGES]: {
    browse: { path: '/challenges' },
    my: { path: '/challenges/my' },
    new_challenge: { path: '/challenges/new_challenge', only: [Role.Admin] },
    edit: { path: '/challenges/edit/:id', only: [Role.Admin], invisible: true },
    submit: { path: '/challenges/submit/:id', invisible: true },
  },
  [RouteEnum.DASHBOARD]: {
    dashboard: { path: '/' },
    approvals: { path: '/approvals', only: [Role.Manager] },
    attempts: { path: '/attempts', only: [Role.Manager] },
  },
  [RouteEnum.TEAMS]: {
    teams: { path: '/teams' },
  },
  [RouteEnum.USERS]: {
    users: { path: '/users' },
  },
  [RouteEnum.SETTINGS]: {
    details: { path: '/settings' },
  },
  [RouteEnum.ADMIN]: {
    admin: { path: '/admin', only: [Role.Superuser] },
  },
};
