import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  ORANGE_RED,
  LIGHTNING_YELLOW,
  MAIZE_CRAYOLA_YELLOW,
  PISTACHIO_GREEN,
  ZOMP_BLUE,
  QUEEN_BLUE,
} from 'src/style/colors';

const bounce = keyframes`
  0%   { transform: translateY(0); }
  15%  { transform: translateY(-10px); }
  30%  { transform: translateY(0); }
  100% { transform: translateY(0); }
`;

const Center = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  display: flex;
`;

const Letter = styled.div<{ delay: number; color: string }>`
  color: ${(p) => p.color};
  font-size: 64px;
  margin-right: -4px;
  animation: ${bounce} 2s linear infinite;
  animation-delay: ${(p) => p.delay}s;
`;

export const Loading: React.FC = () => {
  return (
    <Center>
      <Letter color={QUEEN_BLUE} delay={0}>
        I
      </Letter>
      <Letter color={ZOMP_BLUE} delay={0.2}>
        n
      </Letter>
      <Letter color={ZOMP_BLUE} delay={0.4}>
        t
      </Letter>
      <Letter color={PISTACHIO_GREEN} delay={0.6}>
        e
      </Letter>
      <Letter color={MAIZE_CRAYOLA_YELLOW} delay={0.8}>
        g
      </Letter>
      <Letter color={LIGHTNING_YELLOW} delay={1}>
        r
      </Letter>
      <Letter color={LIGHTNING_YELLOW} delay={1.2}>
        i
      </Letter>
      <Letter color={ORANGE_RED} delay={1.4}>
        g
      </Letter>
      <Letter color={ORANGE_RED} delay={1.6}>
        o
      </Letter>
    </Center>
  );
};

const Wrapper = styled.div`
  width: inherit;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
`;

export const LoadingComponent: React.FC = () => {
  return (
    <Wrapper>
      <Loading />
    </Wrapper>
  );
};
