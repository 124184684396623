import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import upperFirst from 'lodash/upperFirst';

import { WHITE, SECONDARY_COLOR } from 'src/style/colors';
import { messages } from 'src/utils/translations/messages/routes';
import { getRoutePath, getAvailablesRoutes } from 'src/utils/helpers/routing';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useCurrentUser, useCurrentRoute } from 'src/utils/hooks';
import { Logo } from './Logo';
import { Status } from './Status';
import { PrivacyNotice } from '../../PrivacyNotice';

const { Sider } = Layout;

const SideBar = styled(Sider)`
  background-color: ${WHITE};
  display: block;
  margin-left: 24px;
  z-index: 20;

  @media (max-width: 700px) {
    display: none;
  }
`;

const Option = styled.div<{ active: boolean }>`
  text-align: center;
  font-size: 28px;
  margin: 24px;

  & a {
    color: ${(p) => (p.active ? SECONDARY_COLOR : 'auto')};
  }
`;

const LogoWrapper = styled.div`
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 22px;
`;

const NoticeWrapper = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
`;

export const Navigation: React.FC = () => {
  const { formatMessage } = useIntl();
  const { user } = useCurrentUser();
  const currentRoute = useCurrentRoute();

  const availableRoutes = getAvailablesRoutes(user?.roles);

  return (
    <SideBar width={280}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>

      <Status />

      {availableRoutes.map((route) => (
        <Option active={currentRoute === route} key={route}>
          <Link to={getRoutePath(route)}>
            {upperFirst(formatMessage(get(messages, route)))}
          </Link>
        </Option>
      ))}

      <NoticeWrapper>
        <PrivacyNotice />
      </NoticeWrapper>
    </SideBar>
  );
};
